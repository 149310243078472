<template>
  <div id="app">
    <vue-particles
        color="#5928B1"
        :particleOpacity="0.7"
        :particlesNumber="60"
        shapeType="circle"
        :particleSize="4"
        linesColor="#fff"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="2"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
        class="lizi"
    >
    </vue-particles>
    <div class="bg">
      <div class="login">
        <el-container label="登录">
          <el-form :model="loginForm" :rules="loginRules" ref="loginForm" label-width="100px" class="demo-loginForm">
            <el-form-item label="用户名" prop="name">
              <el-input v-model="loginForm.username"
                        placeholder="请输入用户名"
                        autocomplete="on"
              ></el-input>
            </el-form-item>


            <el-form-item label="密码" prop="pass">
              <el-input :type="passwordType"
                        v-model="loginForm.password"
                        auto-complete="off"
                        :key="passwordType"
                        ref="password"
                        placeholder="请输入密码"
                        name="password"
                        autocomplete="off"
                        @keyup.native="checkCapslock"

              ></el-input>
              <svg-icon class-name="size-icon" icon-class="size"/>
              <span class="show-pwd" @click="showPwd">
            <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"/>
          </span>
            </el-form-item>

            <el-form-item>
              <el-button :loading="loading" type="primary" @click="handleLogin">登录
              </el-button>
              <el-button @click="resetForm()">重置</el-button>
            </el-form-item>


          </el-form>


        </el-container>
      </div>
    </div>

  </div>
</template>
<script>

import SvgIcon from "@/components/SvgIcon";

export default {
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入账号！'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }


    return {
      loading: false,
      passwordType: 'password',
      loginForm: {
        username: '',
        password: ''
      },

      loginRules: {
        username: [{required: true, trigger: 'blur', message: '请输入您的用户名', validator: validateUsername}],
        password: [{required: true, trigger: 'blur', message: '长度在6个以上', validator: validatePassword}]
      },
      capsTooltip: false,
      redirect: undefined,

    };
  },
  methods: {
    //选项卡切换
    checkCapslock(e) {
      const {key} = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    //重置表单
    resetForm() {
      this.loginForm = {
        username: '',
        password: ''
      }
    },
    //提交表单
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true

          this.$store.dispatch('user/login', this.loginForm)
              .then(() => {
                // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
                this.loading = false
                this.$message({
                  type: 'success',
                  message: '登录成功'
                });
                this.$router.push({path: '/dataKanban'})

              })
              .catch((error) => {
                this.loading = false
                this.$message({
                  type: 'error',
                  message: error
                });
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
}
</script>
<style lang="scss" scoped>
$dark_gray: #889aa4;
.bg {
  width: 100%;
  height: 100%;
}

.login {
  position: absolute;
  width: 500px;
  height: 400px;
  right: calc(50% - 250px);
  bottom: calc(50% - 250px);
}

.show-pwd {
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 16px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}
</style>