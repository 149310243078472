//懒加载
const FeedBack = () => import('@/views/main/App/FeedBack')
const LogcatList = () => import('@/views/main/App/logcat/LogcatList.vue')
const LogcatDetailList = () => import('@/views/main/App/logcat/LogcatDetailList.vue')
const Permission = () => import('@/views/main/App/Permission.vue')

const LogcatDevices = () => import('@/views/main/App/logcat/LogcatDevices.vue')
const Home = () => import('@/views/main/Home/Home')
const Empty = () => import('@/components/EmptyRouterView')
const AppManager = () => import('@/views/main/App/Manager')
const FeatureManager = () => import('@/views/main/App/feature/FeatureManager')
const PublishApp = () => import('@/views/main/App/PublishApp')
const Love = () => import( '@/views/main/App/manager/LoveReview.vue')
const LoveCms = () => import( '@/views/main/App/cms/LoveCMS')
const GoodsCms = () => import( '@/views/main/App/cms/goods/GoodsCms')
const Classification = () => import( '@/views/main/App/cms/goods/Classification')
const PublishGoods = () => import( '@/views/main/App/cms/goods/PublishGoods')
const GoodsOrderCms = () => import( '@/views/main/App/cms/order/GoodsOrderCms')
const EditGoodsOrder = () => import( '@/views/main/App/cms/order/EditGoodsOrder')

const VideoWallpaper = () => import('@/views/main/App/manager/VideoWallpaper')
const videoWallpaperCMS = () => import('@/views/main/App/cms/VideoWallpaperCMS')
const Code = () => import('@/views/main/App/manager/Code')
const CodeCMS = () => import('@/views/main/App/cms/CodeCMS')
const VIP = () => import('@/views/main/App/money/VIP')
const IntegralRecharge = () => import('@/views/main/App/money/IntegralList.vue')
const AddIntegral = () => import('@/views/main/App/money/AddIntegral')
const ReduceIntegral = () => import('@/views/main/App/money/ReduceIntegral')
const editOrInsertCode = () => import('@/views/main/App/EditOrInsertCode')
const AddVIP = () => import('@/views/main/App/money/AddVIP')


const Test = () => import( '@/views/main/TestVue')
const IntegralDetail = () => import( '@/views/main/App/integral/IntegralDetail.vue')
const IntegralTotal = () => import( '@/views/main/App/integral/IntegralTotal')
const WithDraw = () => import( '@/views/main/App/withdraw/Withdarw')
const PCController = () => import( '@/views/main/App/pc/PCController.vue')
const BaoTa = () => import( '@/views/main/WebSite/BaoTa.vue')
const Cmds = () => import( '@/views/main/WebSite/Cmds.vue')
const APIError = () => import( '@/views/main/App/monitor/APIError.vue')
const ApiDetailList = () => import( '@/views/main/App/monitor/ApiDetailList.vue')

const MembershipTrial = () => import('@/views/main/App/MembershipTrial.vue')


//https://element.eleme.cn/#/zh-CN/component/icon
const menu = [

    {
        path: "dataKanban", name: 'dataKanban', component: Home, meta: {icon: "s-data", title: "数据看板"}
    }, {
        path: "app/config", meta: {icon: "mobile", title: "App"}, component: Empty, children: [{
            path: "manager", name: "appManager", component: AppManager, meta: {icon: "cpu", title: "APP管理"}
        }, {
            path: "feature", name: "feature", component: FeatureManager, meta: {icon: "ship", title: "功能管理"}
        }, {
            path: "feedback", name: 'feedback', component: FeedBack, meta: {icon: "news", title: "意见反馈"},
        }, {
            path: "logcatDevices",
            name: 'logcatDevices',
            component: LogcatDevices,
            meta: {title: "错误设备列表", hideMenu: true},
        }, {
            path: "publishApp", name: 'publishApp', component: PublishApp, meta: {title: "发布应用", hideMenu: true},
        }, {
            path: "permission",
            name: 'permission',
            component: Permission,
            meta: {icon: "custom-permission", title: "权限管理"},
        }, {
            path: "membership-trial",
            name: 'membership-trial',
            component: MembershipTrial,
            meta: {icon: "custom-permission", title: "会员试用"},
        }, {
            path: "add-vip",
            name: 'add-vip',
            component: AddVIP,
            meta: {icon: "custom-permission", title: "会员赠送"},
        },

        ]
    }, {
        path: "error", meta: {
            icon: "custom-error", title: "错误日志"
        }, component: Empty, children: [{
            path: "api", name: 'api', component: APIError, meta: {icon: "custom-error", title: "所有接口"},
        }, {
            path: "apiDetailList",
            name: 'apiDetailList',
            component: ApiDetailList,
            meta: {title: "Api错误详情页面", hideMenu: true},
        }, {
            path: "logcatList",
            name: 'logcatList',
            component: LogcatList,
            meta: {icon: "custom-cat", title: "错误日志"},
        }, {
            path: "logcatDetailList",
            name: 'logcatDetailList',
            component: LogcatDetailList,
            meta: {title: "App错误详情页面", hideMenu: true},
        },]

    }, {
        path: "app/manager", meta: {icon: "toilet-paper", title: "后台审核"}, component: Empty, children: [{
            path: "love", name: 'love', component: Love, meta: {icon: "custom-love", title: "安果相亲"},
        }, {
            path: "videoWallpaper",
            name: 'videoWallpaper',
            component: VideoWallpaper,
            meta: {icon: "custom-video-wallpaper", title: "视频壁纸"},
        }, {
            path: "code", name: 'code', component: Code, meta: {icon: "custom-code", title: "编程助手"},
        }, {
            path: "editOrInsertCode",
            name: 'editOrInsertCode',
            component: editOrInsertCode,
            meta: {title: "新增或者编辑编程助手", hideMenu: true},
        },]
    },

    {
        path: "app/cms", meta: {icon: "s-promotion", title: "内容管理"}, component: Empty, children: [{
            path: "loveCms", name: 'loveCms', component: LoveCms, meta: {icon: "custom-love", title: "安果相亲"},
        }, {
            path: "videoWallpaperCMS",
            name: 'videoWallpaperCMS',
            component: videoWallpaperCMS,
            meta: {icon: "custom-video-wallpaper", title: "视频壁纸"},
        }, {
            path: "CodeCMS", name: 'CodeCMS', component: CodeCMS, meta: {icon: "custom-code", title: "编程助手"},
        }]
    }, {
        path: "income",
        meta: {icon: "wallet", title: "收益管理"},
        component: Empty,
        children: [{path: "vip", name: 'vip', component: VIP, meta: {icon: "custom-vip", title: "会员"}}, {
            path: "addVip", name: 'addVip', component: AddVIP, meta: {title: "后台添加vip", hideMenu: true},
        }, {
            path: "integralRecharge",
            name: 'integralRecharge',
            component: IntegralRecharge,
            meta: {icon: "custom-point", title: "积分"},

        }, {
            path: "addIntegral", name: 'addIntegral', component: AddIntegral, meta: {title: "积分添加", hideMenu: true},

        }, {
            path: "reduceIntegral",
            name: 'reduceIntegral',
            component: ReduceIntegral,
            meta: {title: "减少，清空积分", hideMenu: true},

        }


        ]
    }, {
        path: "commodity", meta: {icon: "s-goods", title: "商品"}, component: Empty, children: [{
            path: "goodsCms", name: 'goodsCms', component: GoodsCms, meta: {icon: "goods", title: "商品管理"},
        }, {
            path: "publishGoods",
            name: 'publishGoods',
            component: PublishGoods,
            meta: {title: "发布商品", hideMenu: true},
        }, {
            path: "classification",
            name: 'classification',
            component: Classification,
            meta: {icon: "menu", title: "分类管理"},
        }, {
            path: "goodsOrderCms",
            name: 'goodsOrderCms',
            component: GoodsOrderCms,
            meta: {icon: "eleme", title: "订单管理"},
        }, {
            path: "editGoodsOrder",
            name: 'editGoodsOrder',
            component: EditGoodsOrder,
            meta: {title: "编辑订单", hideMenu: true},
        }]
    }, {
        path: "integral", name: 'integral', component: Empty, meta: {icon: "custom-point", title: "积分"}, children: [{
            path: "integralDetail",
            name: 'integralDetail',
            component: IntegralDetail,
            meta: {icon: "goods", title: "详情", hideMenu: true},
        }, {
            path: "integralTotal",
            name: 'integralTotal',
            component: IntegralTotal,
            meta: {title: "排行", icon: "s-data",},
        }, {
            path: "withdrawal", name: 'withdrawal', component: WithDraw, meta: {title: "提现", icon: "custom-money",},
        },


        ]
    }, {
        path: "ai", name: 'ai', component: Empty, meta: {icon: "custom-ai", title: "人工智能"}, children: [{
            path: "computer",
            name: 'computer',
            component: PCController,
            meta: {icon: "custom-computer", title: "电脑管理"},
        }


        ]

    }, {
        path: "website", name: 'website', component: Empty, meta: {icon: "custom-website", title: "站点管理"},

        children: [{
            path: "cmds", name: 'cmds', component: Cmds, meta: {icon: "custom-cmds", title: "命令行"},
        }, {
            path: "baota", name: 'baota', component: BaoTa, meta: {icon: "custom-baota", title: "宝塔"},
        }]
    },


    // {
    //     path: "test", name: 'test', component: Test, meta: {icon: "s-data", title: "测试"}
    // }

];


export default menu;