// src/store/modules/theme.js
const state = {
    isDarkTheme: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
};

function loadStylesheet(url) {
    return new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = url;
        link.onload = () => resolve();
        link.onerror = () => reject();
        document.head.appendChild(link);
    });
}

function removeStylesheet(url) {
    const links = document.querySelectorAll(`link[href="${url}"]`);
    links.forEach(link => link.parentNode.removeChild(link));
}

const mutations = {
    SET_THEME(state, isDarkTheme) {
        state.isDarkTheme = isDarkTheme;
        if (isDarkTheme) {
            loadStylesheet('https://unpkg.com/element-theme-dark@1.0.3/lib/index.css').then(() => {
                document.body.classList.add('dark');
                document.body.classList.remove('light');
            });
            removeStylesheet('https://unpkg.com/element-ui@2.15.14/lib/theme-chalk/index.css');

        } else {
            loadStylesheet('https://unpkg.com/element-ui@2.15.14/lib/theme-chalk/index.css').then(() => {
                document.body.classList.add('light');
                document.body.classList.remove('dark');
            });
            removeStylesheet('https://unpkg.com/element-theme-dark@1.0.3/lib/index.css');

        }
    }
};

const actions = {
    toggleTheme({ commit, state }) {
        commit('SET_THEME', !state.isDarkTheme);
    },
    setSystemTheme({ commit }, isDarkTheme) {
        commit('SET_THEME', isDarkTheme);
    },
    initializeTheme({ commit }) {
        const isDarkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        commit('SET_THEME', isDarkTheme);

        if (window.matchMedia) {
            const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            mediaQuery.addListener(e => {
                commit('SET_THEME', e.matches);
            });
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
