import request from '@/utils/request'


//获取所有的app
export function queryIndexById(id) {
    const data = {};
    data['id'] = id
    return request({
        url: '/app/list/queryIndexById', method: 'get', params: data
    })

}
