//网络请求
import {login,logout} from '@/api/login/auth'

//工具类
import {
    getToken,
    setToken,
    removeToken,
    getTokenType,
    setTokenType,
    removeTokenType,
    removeUserInfo
} from '@/utils/auth'
import {resetRouter} from "@/router";

const state = {
    token: getToken(),
    tokenType: getTokenType(),
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_TOKEN_TYPE: (state, tokenType) => {
        state.tokenType = tokenType
    },
    RESET_TOKEN: (state) => {
        state.token = ''
        state.tokenType = ''
    },

}


const actions = {
    // user login
    login({ commit }, userInfo) {
        const { username, password } = userInfo
        return new Promise((resolve, reject) => {
            login({ username: username, password: password }).then(response => {
                const { data } = response
                commit('SET_TOKEN', data.token)
                commit('SET_TOKEN_TYPE', data.token_type)
                setToken(data.token)
                setTokenType(data.token_type)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    //  退出登录
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            logout().then(() => {
                commit('SET_TOKEN', '')
                commit('SET_TOKEN_TYPE', '')
                commit('SET_USER_INFO', '')
                removeToken()
                removeTokenType()
                removeUserInfo()
                resetRouter()
                // reset visited views and cached views
                // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
                // dispatch('tagsView/delAllViews', null, { root: true })
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // 新增 resetToken action
    resetToken({ commit }) {
        return new Promise(resolve => {
            commit('RESET_TOKEN'); // 清除 Vuex 中的 token
            removeToken(); // 清除浏览器存储中的 token
            removeTokenType(); // 如果有 token 类型也一并清除
            resetRouter(); // 重置路由
            // 如果有其他需要一并清除的用户信息或状态，也应在这里进行
            resolve();
        });
    },

}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
