<template>
  <header>
    <div class="l-content">
      <el-button plain icon="el-icon-menu" size="mini" @click="handleMenu"></el-button>
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="current.path" v-if="current">{{ current.meta.title }}</el-breadcrumb-item>

      </el-breadcrumb>
    </div>
    <div class="r-content">

      <div v-show="isDarkTheme" class="theme-toggle" @click="handleThemeChange">
        <i class="fas fa-moon"></i>
      </div>

      <div v-show="!isDarkTheme"  class="theme-toggle" @click="handleThemeChange">
        <i class="fas fa-sun"></i>
      </div>
      <el-dropdown trigger="click" size="mini" @command="handleCommand">
        <span class="el-dropdown-link">
          <img :src="userImage" alt="" class="user">
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>个人中心</el-dropdown-item>
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

  </header>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
  name: "CommonHeader",
  data() {
    return {
      userImage: require('../assets/image/user.png'),
      isDarkTheme: true, // 默认主题状态
    };
  },

  methods: {
    handleMenu() {
      this.$store.commit("tab/collapseMenu");
    },
    handleCommand(command) {

      switch (command) {
        case "logout":
          this.$store.commit('user/logout')
          this.$router.push(`/login?redirect=${this.$route.fullPath}`)
          break
      }
    },
    handleThemeChange() {
      this.$store.dispatch('theme/toggleTheme');
      this.isDarkTheme = !this.isDarkTheme; // 切换 isDarkTheme 的值


    },
    ...mapActions('theme', ['toggleTheme'])
  }
  ,
  computed: {
    ...
        mapState({current: state => state.tab.currentMenu})


  },
  mounted() {
    // 设置默认主题为跟随系统
    const isDarkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.$store.commit('theme/SET_THEME', isDarkTheme);
    this.isDarkTheme = isDarkTheme; // 初始化时同步 isDarkTheme 的值

  }
}
</script>

<style scoped lang="scss">
header {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.l-content {
  display: flex;
  align-items: center;

  .el-button {
    margin-right: 20px;
  }
}

.r-content {
  display: flex;
  align-items: center;

  .theme-toggle {
    cursor: pointer;
    margin-right: 20px;
    font-size: 1.5em;
    transition: color 0.3s;
  }

  .theme-toggle .fa-sun {
    color: #FFA500; /* Sun color */
  }

  .theme-toggle .fa-moon {
    color: #4B0082; /* Moon color */
  }

  .user {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
</style>

<style lang="scss">
body.light {
  background-color: #ffffff;
  color: #000000;
}

body.dark {
  background-color: #333333;
  color: #ffffff;
}

.el-breadcrumb__item {
  .el-breadcrumb__inner {
    color: #666;
    font-weight: normal;
    transition: color 0.3s;
  }

  &:last-child {
    .el-breadcrumb__inner {
      color: #fff;
      font-weight: bold;
    }
  }
}

body.dark .el-breadcrumb__inner {
  color: #c0c4cc;
}

body.dark .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #fff;
}

body.light .el-breadcrumb__inner {
  color: #666;
}

body.light .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #303133;
}
</style>

