<template>
  <div class="tabs">
    <el-tag v-for="(tag,index) in tags"
            :key="tag.name"
            size="small"
            :closable="tag.meta.title !== '首页'"
            :effect="$route.name === tag.name ? 'dark':'plain'"
            @click="changeMenu(tag)"
            @close="handleClose(tag,index)"
    >
      {{ tag.meta.title }}
    </el-tag>
    <el-tag v-show="tags.length > 2"
            size="small"
            effect="light"
            @click="closeAll()"
    >
      关闭所有
    </el-tag>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: "CommonTag",
  computed: {
    ...mapState({
      tags: (state => state.tab.tabsList)
    })
  },
  methods: {

    changeMenu(item) {
      this.$router.push({name: item.name})
      this.$store.commit("tab/selectMenu", item);
    },
    closeAll() {
      this.$store.commit("tab/closeAllTags")
      this.$router.push({
        name: this.tags[0].name,
      });
    },
    handleClose(tag, index) {
      //判断是否是最后一个
      let length = this.tags.length - 1
      //删除数据
      this.$store.commit("tab/closeTag", tag)
      //第一种情况。
      //不是关闭当前的路由
      if (tag.name !== this.$route.name) {
        return;
      }
      //最后一个 往前移动一个页面
      if (index === length) {
        this.$router.push({
          name: this.tags[index - 1].name,
        });
      } else {
        //否则往后移动一个页面
        this.$router.push({
          name: this.tags[index].name,
        });
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  padding: 20px;

  .el-tag {
    margin-right: 15px;
    cursor: pointer;
  }
}
</style>