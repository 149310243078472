import Vue from 'vue'
import App from './App.vue'
import store from '@/store/store'

// import "./assets/scss/reset.scss"
import "./assets/scss/home.scss"
import "./assets/scss/common.scss"

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';


import './assets/styles/global.css'; // 引入全局样式

import 'element-ui/lib/theme-chalk/index.css'; // 先加载默认样式
import 'element-theme-dark/lib/index.css'; // 再加载暗色主题样式





//svg
import '@/icons/index'


import http from 'axios';
// 全部引入
import ElementUI from 'element-ui';


Vue.use(ElementUI);

import 'element-ui/lib/theme-chalk/index.css';
//粒子特效
import VueParticles from 'vue-particles'

Vue.use(VueParticles)
//复制
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

import router from '@/router/index'
//视频播放
import 'video.js/dist/video-js.css'
import VideoPlayer from 'vue-video-player'

Vue.use(VideoPlayer)

Vue.filter('formatDate', function (value) {
    if (value) {
        const date = new Date(value * 1000);
        return date.toISOString().slice(0, 19).replace('T', ' ');
    }
});


Vue.prototype.$http = http;
/* 或写为
 * Vue.use(Button)
 * Vue.use(Select)
 */

Vue.config.productionTip = false
//判断环境 并引入mock
// if (process.env.NODE_ENV === 'development') require('@/api/mock')
new Vue({
    router: router, store, render: h => h(App), mounted() {
        // 调用初始化主题的逻辑
        this.$store.dispatch('theme/initializeTheme');

    }

}).$mount('#app')
