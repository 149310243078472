import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token-Cms-Blog'
const TokenTypeKey = 'Admin-Token-Type'

const UserInfo = 'Admin-Token-Cms-Blog-User-Info'
// const ModelId = 'Admin-Model-Id-Cms-Blog'
// const ModelName = 'Admin-Model-Name-Cms-Blog'
// const MainInfo = 'Admin-Main-info-Blog'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function getTokenType() {
    return Cookies.get(TokenTypeKey)
}

export function setTokenType(tokenType) {
    return Cookies.set(TokenTypeKey, tokenType)
}

export function removeTokenType() {
    return Cookies.remove(TokenTypeKey)
}

export function getUserInfo() {
    return Cookies.get(UserInfo)
}

export function setUserInfo(user) {
    return Cookies.set(UserInfo, user)
}

export function removeUserInfo() {
    return Cookies.remove(UserInfo)
}