<template>
  <!--    default-active  默认展开项目-->
  <el-menu
      class="el-menu-vertical-demo"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      default-active="2"
      :collapse="isCollapse">
    <div @click="goHome()">
      <h3 v-show="!isCollapse">安果后台CMS</h3>
      <h3 v-show="isCollapse">CMS</h3>
    </div>

    <div v-for="(item,key) in menuData " :key=key>
      <el-menu-item v-if="!item.children" :key=key :index="item.path"
                    @click="clickMenu(item)">
        <i v-if="item.meta.icon" :class="'el-icon-'+item.meta.icon"></i>
        <el-image v-else :src="require(item.meta.image)"></el-image>
        <span slot="title">{{ item.meta.title }}</span>
      </el-menu-item>


      <!--    遍历带有子控件的-->
      <el-submenu v-else :index="item.path" :key=item.path>
        <template slot="title">
          <i v-if="item.meta.icon" :class="'el-icon-'+item.meta.icon"></i>
          <el-image class="" v-else :src="require(item.meta.image)"></el-image>
          <span slot="title">{{ item.meta.title }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item :index="subItem.path" v-for="(subItem,subIndex) in item.children" :key="subIndex"
                        @click="clickMenu(subItem)">
            <i v-if="subItem.meta.icon" :class="'el-icon-'+subItem.meta.icon"></i>
            <!--            <i v-else class="image_icon"-->
            <!--               :styles="{ backgroundImage : item.meta.image }">aa</i>-->

            <!--            <el-image v-else class="image_icon" :src="subItem.meta.image"></el-image>-->


            <span slot="title">{{ subItem.meta.title }}</span>
          </el-menu-item>
        </el-menu-item-group>

      </el-submenu>


    </div>


  </el-menu>
</template>

<style lang="scss" scoped>

.el-icon-custom-code {
  background: url(../assets/image/app/code.png) center no-repeat;
  background-size: contain;
}

.el-icon-custom-code:before {
  content: "编程助手";
  font-size: 16px;
  visibility: hidden;
}

.el-icon-custom-love {
  background: url(../assets/image/app/love.png) center no-repeat;
  background-size: contain;
}

.el-icon-custom-love:before {
  content: "相亲";
  font-size: 16px;
  visibility: hidden;
}

.el-icon-custom-video-wallpaper {
  background: url(../assets/image/app/video_wallpaper.png) center no-repeat;
  background-size: contain;
}


.el-icon-custom-video-wallpaper:before {
  content: "视频壁纸";
  font-size: 16px;
  visibility: hidden;
}


.image_icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-position: center center;
}

.el-menu {
  height: 100%;
  border: none;

  h3 {
    color: #ffffff;
    text-align: center;
    line-height: 48px;
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}


.el-icon-custom-point {
  background: url(../assets/image/app/point.svg) center no-repeat;
  background-size: contain;
}


.el-icon-custom-point:before {
  content: "积分充值";
  font-size: 16px;
  visibility: hidden;
}

.el-icon-custom-money {
  background: url(../assets/image/app/money.svg) center no-repeat;
  background-size: contain;
}


.el-icon-custom-money:before {
  content: "收益管理";
  font-size: 16px;
  visibility: hidden;
}

.el-icon-custom-vip {
  background: url(../assets/image/app/vip.svg) center no-repeat;
  background-size: contain;
}

.el-icon-custom-vip:before {
  content: "会员";
  font-size: 16px;
  visibility: hidden;
}

.el-icon-custom-ai {
  background: url(../assets/image/app/ai.svg) center no-repeat;
  background-size: contain;
}

.el-icon-custom-ai:before {
  content: "人工智能";
  font-size: 16px;
  visibility: hidden;
}

.el-icon-custom-website {
  background: url(../assets/image/app/website.svg) center no-repeat;
  background-size: contain;
}

.el-icon-custom-website:before {
  content: "站点配置";
  font-size: 16px;
  visibility: hidden;
}

.el-icon-custom-baota {
  background: url(../assets/image/app/baota.svg) center no-repeat;
  background-size: contain;
}

.el-icon-custom-baota:before {
  content: "宝塔";
  font-size: 16px;
  visibility: hidden;
}

.el-icon-custom-cmds {
  background: url(../assets/image/app/cmds.svg) center no-repeat;
  background-size: contain;
}

.el-icon-custom-cmds:before {
  content: "命令行";
  font-size: 16px;
  visibility: hidden;
}


.el-icon-custom-computer {
  background: url(../assets/image/app/computer.svg) center no-repeat;
  background-size: contain;
}

.el-icon-custom-computer:before {
  content: "电脑管理";
  font-size: 16px;
  visibility: hidden;
}

.el-icon-custom-cat {
  background: url(../assets/image/app/cat.svg) center no-repeat;
  background-size: contain;
}

.el-icon-custom-cat:before {
  content: "app错误";
  font-size: 16px;
  visibility: hidden;
}

.el-icon-custom-error {
  background: url(../assets/image/app/error.svg) center no-repeat;
  background-size: contain;
}

.el-icon-custom-error:before {
  content: "错误日志";
  font-size: 16px;
  visibility: hidden;
}

.el-icon-custom-monitor {
  background: url(../assets/image/app/monitor.svg) center no-repeat;
  background-size: contain;
}

.el-icon-custom-monitor:before {
  content: "监控日至";
  font-size: 16px;
  visibility: hidden;
}

.el-icon-custom-permission {
  background: url(../assets/image/app/monitor.svg) center no-repeat;
  background-size: contain;
}

.el-icon-custom-permission:before {
  content: "权限管理";
  font-size: 16px;
  visibility: hidden;
}



</style>


<script>
import menu from '@/router/menu'

export default {
  name: "CommonAside",
  data() {
    return {
      //展开 还是折叠
      menuData: null
    };
  },
  methods: {

    clickMenu(item) {
      this.$router.push({name: item.name});
      this.$store.commit("tab/selectMenu", item)
    },
    goHome() {
      this.$router.push({name: 'home'});
      this.$store.commit("tab/selectMenu", {name: 'home'})

    }
  },
  computed: {
    isCollapse() {
      return this.$store.getters.isCollapsed;
    }
  },
  created() {
    //过滤不需要显示得菜单
    // const noShowPath = menu.filter(item => item &&)

    this.menuData = menu.map((item) => {

      if (Object.prototype.hasOwnProperty.call(item, "children")) {
        item.children = item.children.filter((item1) => {
          return !Object.prototype.hasOwnProperty.call(item1.meta, "hideMenu")
        })
      }
      return item
    })

    // hideMenu

  }
}
</script>
