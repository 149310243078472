import request from '@/utils/request'

//用户登录
export function login(data) {
    return request({
        url: '/login/login',
        method: 'post',
        data:data
    })
}

//用户登录
export function logout() {
    return request({
        url: '/login/logout',
        method: 'post',
    })
}